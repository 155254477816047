import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

const createCube6 = () => {
  const loader = new GLTFLoader();
  const group = new THREE.Group(); // Placeholder group

  loader.load(
    '/assets/purplegem.gltf', // Path to the GLTF model
    (gltf) => {
      const model = gltf.scene; // Access the loaded model
      model.scale.set(2.0, 2.5, 2.5); // Scale the model to fit your scene
      model.rotation.y = Math.PI / 4; // Adjust rotation for aesthetic effect
      group.add(model); // Add the loaded model to the placeholder group
    },
    undefined,
    (error) => {
      console.error('Error loading purplegem.gltf:', error); // Log any errors
    }
  );

  return group; // Return the group with or without the model
};

export default createCube6;
