import React from "react";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

// Import Solana wallet adapter button
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";

// Import widgets
import Widget3 from "./widgets/Widget3";
import Widget1 from "./widgets/Widget1"; // Assuming Widget4 is "Pool"
import Widget2 from "./widgets/Widget2"; // Assuming Widget5 is "Buy Tickets"

import { PoolProvider } from "../context/PoolContext"; // Import PoolProvider

function PlayPage() {
  return (
    <PoolProvider>
      <div style={{ width: "100vw", height: "100vh", padding: "20px" }}>
        {/* Fixed Header */}
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            backgroundColor: "#fff",
            zIndex: 1000,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 20px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <h1 style={{ margin: 0 }}>Play Page</h1>
          <WalletMultiButton />
        </div>

        {/* Content Section */}
        <div style={{ marginTop: "70px" }}>
          {/* Full-width 3D Scene */}
          <div
            style={{
              width: "100%",
              height: "60vh", // Adjust height to fit the screen
              marginBottom: "20px",
            }}
          >
            <Widget3 />
          </div>

          {/* Pool and Buy Tickets Section */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "stretch",
              gap: "20px",
            }}
          >
            {/* Pool Widget */}
            <div
              style={{
                flex: 1,
                minHeight: "40vh",
                backgroundColor: "#f9f9f9",
                borderRadius: "8px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                padding: "20px",
              }}
            >
              <Widget1 />
            </div>

            {/* Buy Tickets Widget */}
            <div
              style={{
                flex: 1,
                minHeight: "40vh",
                backgroundColor: "#f9f9f9",
                borderRadius: "8px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                padding: "20px",
              }}
            >
              <Widget2 />
            </div>
          </div>
        </div>
      </div>
    </PoolProvider>
  );
}

export default PlayPage;
