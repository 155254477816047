import * as THREE from 'three';

const createCube4 = () => {
  const material = new THREE.MeshBasicMaterial({ color: 0x0000ff }); // Blue
  const geometry = new THREE.SphereGeometry(1, 32, 32);  const cube = new THREE.Mesh(geometry, material);

  // Position the cube
  cube.position.set(6, 0, 0);

  return cube;
};

export default createCube4;
