import React, { useRef, useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { OrbitControls, CubeCamera, Environment } from "@react-three/drei";
import { EffectComposer, Bloom } from "@react-three/postprocessing";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import * as THREE from "three";

// Import your cube creators
import createCube1 from "./cubes/cube1";
import createCube2 from "./cubes/cube2";
import createCube4 from "./cubes/cube4";
import createCube5 from "./cubes/cube5";
import createCube6 from "./cubes/cube6";
import createCube7 from "./cubes/cube7";
import createCube8 from "./cubes/cube8";
import createCube9 from "./cubes/cube9";
import createCube10 from "./cubes/cube10";
import createCube11 from "./cubes/cube11";
import createCube12 from "./cubes/cube12";

// Custom orbiting ring component
const OrbitingRing = ({ radius, color, speed, positionY }) => {
  const ringRef = useRef();

  useFrame(({ clock }) => {
    const elapsed = clock.getElapsedTime();
    if (ringRef.current) {
      ringRef.current.rotation.z = elapsed * speed; // Slow rotation
    }
  });

  return (
    <mesh ref={ringRef} position={[0, positionY, 0]}>
      <ringGeometry args={[radius, radius + 0.2, 64]} />
      <meshBasicMaterial color={color} side={THREE.DoubleSide} />
    </mesh>
  );
};

// Custom orbiting object
const OrbitingObject = ({ radius, size, speed, color }) => {
  const objectRef = useRef();

  useFrame(({ clock }) => {
    const elapsed = clock.getElapsedTime();
    if (objectRef.current) {
      objectRef.current.position.set(
        radius * Math.cos(elapsed * speed),
        Math.sin(elapsed * speed),
        radius * Math.sin(elapsed * speed)
      );
    }
  });

  return (
    <mesh ref={objectRef}>
      <sphereGeometry args={[size, 32, 32]} />
      <meshStandardMaterial color={color} emissive={color} emissiveIntensity={0.5} />
    </mesh>
  );
};

// Wallet Tracking Cube3
const Cube3Wallet = ({ setWalletVisible, setWalletPosition }) => {
  const buttonRef = useRef();

  useFrame(({ clock, camera }) => {
    const elapsed = clock.getElapsedTime();

    // Wallet Button Rotation and Oscillation
    if (buttonRef.current) {
      const radius = 2;
      const x = radius * Math.cos(elapsed * 0.5);
      const y = 2 + Math.sin(elapsed * 0.5);
      const z = radius * Math.sin(elapsed * 0.5);

      buttonRef.current.position.set(x, y, z);
      buttonRef.current.lookAt(camera.position);

      setWalletPosition({ x, y, z });
    }
  });

  return (
    <mesh
      ref={buttonRef}
      onClick={(e) => {
        e.stopPropagation();
        setWalletVisible(true);
      }}
    >
      <sphereGeometry args={[1.5, 32, 32]} />
      <meshStandardMaterial color="blue" />
    </mesh>
  );
};

// Group Component
const Group = ({ position, cubeCreators, groupName, setTooltip, setFocusedGroup, setWalletVisible }) => {
  const groupRef = useRef();
  const cubes = cubeCreators.map((createCube) => createCube({ setWalletVisible }));

  useFrame(({ clock }) => {
    const elapsed = clock.getElapsedTime();

    // Rotate the group
    if (groupRef.current) {
      groupRef.current.rotation.y = elapsed * 0.2;
    }

    // Animate individual cubes
    cubes.forEach((cube, i) => {
      const localRadius = 8 + i * 3;
      const localSpeed = 0.8 + i * 0.1;
      const localAngle = elapsed * localSpeed;

      cube.position.set(
        localRadius * Math.cos(localAngle),
        Math.sin(elapsed * 0.5) * 2,
        localRadius * Math.sin(localAngle)
      );
    });
  });

  return (
    <group
      ref={groupRef}
      position={position}
      onClick={(event) => {
        event.stopPropagation();
        setFocusedGroup(position);
        setTooltip({ visible: true, message: `You are exploring ${groupName}. Tap elsewhere to continue.` });
      }}
    >
      {cubes.map((cube, i) => (
        <primitive key={i} object={cube} />
      ))}
    </group>
  );
};

// Scene Content
const SceneContent = ({ setFocusedGroup, focusedGroup, setTooltip, setWalletVisible, setWalletPosition }) => {
  const orbitControlsRef = useRef();

  useFrame(({ camera }) => {
    if (focusedGroup) {
      const [x, y, z] = focusedGroup;
      camera.position.lerp(new THREE.Vector3(x, y + 20, z + 100), 0.1);
      orbitControlsRef.current.target.lerp(new THREE.Vector3(x, y, z), 0.1);
    }
  });

  return (
    <>
      <OrbitControls ref={orbitControlsRef} enablePan enableZoom enableRotate maxDistance={400} minDistance={50} />
      <hemisphereLight intensity={0.8} groundColor="#ffb6c1" skyColor="white" position={[0, 50, 0]} />
      <pointLight intensity={1} color="pink" position={[0, 50, 50]} />
      <pointLight intensity={0.8} color="white" position={[-50, -50, -50]} />

      {/* Rings and Orbiting Effects */}
      <OrbitingRing radius={15} color="lightblue" speed={0.1} positionY={0} />
      <OrbitingRing radius={25} color="gold" speed={0.05} positionY={-5} />
      <OrbitingObject radius={10} size={1} speed={0.5} color="purple" />
      <OrbitingObject radius={20} size={1.5} speed={0.3} color="green" />

      {/* Group 1 */}
      <Group
        position={[0, 0, -50]}
        groupName="Group 1"
        cubeCreators={[createCube1, createCube2, () => new THREE.Mesh(new THREE.BoxGeometry(2, 2, 2))]}
        setTooltip={setTooltip}
        setFocusedGroup={setFocusedGroup}
        setWalletVisible={setWalletVisible}
      />

      {/* Cube3 Wallet Button */}
      <Cube3Wallet setWalletVisible={setWalletVisible} setWalletPosition={setWalletPosition} />

      {/* Additional Groups */}
      <Group
        position={[0, 0, -150]}
        groupName="Group 2"
        cubeCreators={[createCube4, createCube5, createCube6, createCube7, createCube8]}
        setTooltip={setTooltip}
        setFocusedGroup={setFocusedGroup}
      />
      <Group
        position={[0, 0, -300]}
        groupName="Group 3"
        cubeCreators={[createCube9, createCube10, createCube11, createCube12]}
        setTooltip={setTooltip}
        setFocusedGroup={setFocusedGroup}
      />
    </>
  );
};

// Main Orb Scene
const OrbScene = () => {
  const [focusedGroup, setFocusedGroup] = useState(null);
  const [tooltip, setTooltip] = useState({ visible: false, message: "" });
  const [walletVisible, setWalletVisible] = useState(false);
  const [walletPosition, setWalletPosition] = useState({ x: 0, y: 0, z: 0 });

  const handleCanvasClick = () => {
    if (walletVisible) {
      setWalletVisible(false);
    }
  };

  return (
    <>
      <Canvas
        style={{ height: "100vh", background: "black" }}
        onPointerMissed={() => {
          handleCanvasClick();
          setFocusedGroup(null);
        }}
      >
        <SceneContent
          setFocusedGroup={setFocusedGroup}
          focusedGroup={focusedGroup}
          setTooltip={setTooltip}
          setWalletVisible={setWalletVisible}
          setWalletPosition={setWalletPosition}
        />
        <EffectComposer>
          <Bloom intensity={1.5} luminanceThreshold={0.2} luminanceSmoothing={0.9} />
        </EffectComposer>
      </Canvas>

      {/* Tooltip */}
      {tooltip.visible && (
        <div
          style={{
            position: "absolute",
            bottom: "20px",
            left: "50%",
            transform: "translateX(-50%)",
            padding: "10px 20px",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            color: "white",
            borderRadius: "8px",
            textAlign: "center",
            fontSize: "14px",
            zIndex: 1000,
          }}
        >
          {tooltip.message}
        </div>
      )}

      {/* Wallet UI */}
      {walletVisible && (
        <div
          className={`wallet-button-container ${walletVisible ? "wallet-connected" : ""}`}
          style={{
            position: "absolute",
            left: `${50 + walletPosition.x * 5}%`,
            top: `${50 - walletPosition.y * 5}%`,
            transform: "translate(-50%, -50%)",
          }}
        >
          <WalletMultiButton />
        </div>
      )}
    </>
  );
};

export default OrbScene;
