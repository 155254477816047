import * as THREE from 'three';

const createCube7 = () => {
  const geometry = new THREE.BoxGeometry(1, 5, 5);
  // Change color to pink
  const material = new THREE.MeshStandardMaterial({ color: 0xFFC0CB }); // Pink
  const cube = new THREE.Mesh(geometry, material);

  cube.position.set(0, 0, 0); // Default position
  return cube;
};

export default createCube7;


