import React, { useEffect, useRef } from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

const HomePage = () => {
  const mountRef = useRef(null); // Ref for container div
  const rendererRef = useRef(null); // Ref for renderer
  const sceneRef = useRef(null); // Ref for scene
  const cameraRef = useRef(null); // Ref for camera
  const globeRef = useRef(null); // Ref for the globe object

  useEffect(() => {
    // Ensure this runs only once
    if (!mountRef.current) return;

    // Detect if the device is mobile
    const isMobile = window.innerWidth < 768;

    // Scene, Camera, Renderer
    const scene = new THREE.Scene();
    sceneRef.current = scene;

    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    camera.position.set(0, isMobile ? 20 : 50, isMobile ? 120 : 150); // Adjust camera for mobile
    cameraRef.current = camera;

    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(window.devicePixelRatio); // High DPI support
    mountRef.current.appendChild(renderer.domElement); // Attach renderer to the container div
    rendererRef.current = renderer;

    // Add Lighting
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.8);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.6);
    directionalLight.position.set(5, 10, 7.5);
    scene.add(directionalLight);

    // Load GLTF Model
    const loader = new GLTFLoader();
    loader.load(
      "/scene.gltf",
      (gltf) => {
        const globe = gltf.scene;
        globeRef.current = globe;
        scene.add(globe);

        // Adjust globe position and scale
        globe.position.set(0, 0, 0);
        globe.scale.set(isMobile ? 0.8 : 1, isMobile ? 0.8 : 1, isMobile ? 0.8 : 1); // Smaller scale for mobile
      },
      undefined,
      (error) => {
        console.error("An error occurred while loading the model:", error);
      }
    );

    // OrbitControls for Camera
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;
    controls.enableZoom = true;
    controls.zoomSpeed = isMobile ? 0.8 : 1.2; // Slower zoom for mobile
    controls.enablePan = !isMobile; // Disable pan on mobile for simplicity
    controls.rotateSpeed = isMobile ? 0.6 : 1.0; // Slower rotation on mobile
    controls.maxPolarAngle = Math.PI;
    controls.minPolarAngle = 0;

    // Animation Loop
    const animate = () => {
      requestAnimationFrame(animate);

      // Rotate the globe if it exists
      if (globeRef.current) {
        globeRef.current.rotation.y += 0.002; // Adjust rotation speed
        globeRef.current.rotation.x += 0.001;
      }

      controls.update(); // Update orbit controls
      renderer.render(scene, camera);
    };

    animate();

    // Handle Window Resize
    const onWindowResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };
    window.addEventListener("resize", onWindowResize);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("resize", onWindowResize);

      if (rendererRef.current) {
        rendererRef.current.dispose();
        rendererRef.current = null;
      }

      if (sceneRef.current) {
        while (sceneRef.current.children.length > 0) {
          sceneRef.current.remove(sceneRef.current.children[0]);
        }
        sceneRef.current = null;
      }

      if (mountRef.current) {
        mountRef.current.innerHTML = ""; // Clear container
      }

      globeRef.current = null;
    };
  }, []);

  return <div ref={mountRef} style={{ width: "100vw", height: "100vh" }} />;
};

export default HomePage;
