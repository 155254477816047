import * as THREE from 'three';

const createCube10 = () => {
  const material = new THREE.MeshBasicMaterial({ color: 0xff0770 }); // Red
  const geometry = new THREE.BoxGeometry();
  const cube = new THREE.Mesh(geometry, material);

  // Position the cube
  cube.position.set(0, 0, 0);

  return cube;
};

export default createCube10;
