import * as THREE from 'three';

const createCube2 = () => {
  const material = new THREE.MeshStandardMaterial({
    color: 0x00ff00, // Green color
    emissive: 0x00ff00, // Green glow
    emissiveIntensity: 0.5, // Adjust intensity of the glow
    metalness: 0.3, // Adds some metallic effect
    roughness: 0.7, // Controls the roughness of the surface
  });

  const geometry = new THREE.SphereGeometry(1, 32, 32);
  const cube = new THREE.Mesh(geometry, material);

  // Position the cube
  cube.position.set(0, 0, 0);

  return cube;
};

export default createCube2;
