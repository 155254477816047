import React, { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";

const Widget3 = () => {
  const mountRef = useRef(null);
  const raycaster = useRef(new THREE.Raycaster());
  const mouse = useRef(new THREE.Vector2());
  const [walletVisible, setWalletVisible] = useState(false);

  useEffect(() => {
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0x0d1117);

    const camera = new THREE.PerspectiveCamera(
      75,
      mountRef.current.offsetWidth / mountRef.current.offsetHeight,
      0.1,
      1000
    );
    camera.position.set(0, 50, 150);

    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(mountRef.current.offsetWidth, mountRef.current.offsetHeight);
    renderer.setPixelRatio(window.devicePixelRatio);
    mountRef.current.appendChild(renderer.domElement);

    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;

    // Add lights
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.6);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
    directionalLight.position.set(50, 50, 50);
    scene.add(directionalLight);

    // Central Sphere
    const centralSphere = new THREE.Mesh(
      new THREE.SphereGeometry(10, 32, 32),
      new THREE.MeshStandardMaterial({ color: 0x00aaff, roughness: 0.5 })
    );
    scene.add(centralSphere);

    // Load texture for Phantom Wallet Button
    const textureLoader = new THREE.TextureLoader();
    const phantomTexture = textureLoader.load("/phantom3.png");

    // Create Phantom Wallet Button
    const phantomButtonGeometry = new THREE.CircleGeometry(4, 64); // Rounded edges
    const phantomButtonMaterial = new THREE.MeshBasicMaterial({
      map: phantomTexture,
      transparent: true,
    });
    const phantomButton = new THREE.Mesh(phantomButtonGeometry, phantomButtonMaterial);
    phantomButton.userData.isWalletButton = true; // Mark as wallet button
    phantomButton.position.set(30, 0, 0);
    scene.add(phantomButton);

    // Animation Loop
    const animate = () => {
      requestAnimationFrame(animate);
      const time = performance.now() * 0.0002; // Slower orbit

      // Orbit the Phantom button
      const radius = 30;
      phantomButton.position.set(
        radius * Math.cos(time),
        Math.sin(time) * 2, // Vertical oscillation
        radius * Math.sin(time)
      );

      // Rotate the button to always face the camera
      phantomButton.lookAt(camera.position);

      controls.update();
      renderer.render(scene, camera);
    };

    animate();

    // Handle mouse click for interactivity
    const handleMouseClick = (event) => {
      const rect = mountRef.current.getBoundingClientRect();
      mouse.current.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
      mouse.current.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

      raycaster.current.setFromCamera(mouse.current, camera);
      const intersects = raycaster.current.intersectObjects(scene.children, true);

      if (intersects.length > 0) {
        const clickedObject = intersects[0].object;
        if (clickedObject.userData.isWalletButton) {
          setWalletVisible(true); // Show the wallet UI
        }
      }
    };

    const handleSceneClick = (event) => {
      if (walletVisible) {
        setWalletVisible(false); // Hide wallet UI when clicking anywhere else
      }
    };

    const handleResize = () => {
      const { offsetWidth, offsetHeight } = mountRef.current;
      renderer.setSize(offsetWidth, offsetHeight);
      camera.aspect = offsetWidth / offsetHeight;
      camera.updateProjectionMatrix();
    };

    window.addEventListener("click", handleMouseClick);
    window.addEventListener("click", handleSceneClick);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("click", handleMouseClick);
      window.removeEventListener("click", handleSceneClick);

      if (renderer) {
        renderer.dispose();
        mountRef.current.removeChild(renderer.domElement);
      }
    };
  }, [walletVisible]);

  return (
    <div
      ref={mountRef}
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      {walletVisible && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "20px",
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            borderRadius: "8px",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
            zIndex: 10,
          }}
        >
          <WalletMultiButton />
        </div>
      )}
    </div>
  );
};

export default Widget3;
