import React, { useState, useEffect, useRef } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { Connection, PublicKey, SystemProgram, Transaction } from "@solana/web3.js";
import { usePool } from "../../context/PoolContext";

// Establish a connection to the Solana cluster
const connection = new Connection(
  process.env.REACT_APP_SOLANA_RPC_URL || "https://api.devnet.solana.com",
  "confirmed"
);

// Load the treasury wallet public key from environment variables
const TREASURY_WALLET = process.env.REACT_APP_TREASURY_WALLET;

const Widget2 = () => {
  const { selectedPool } = usePool(); // Access the selected pool from context
  const { publicKey, sendTransaction, connected, signTransaction } = useWallet(); // Wallet functions
  const [tickets, setTickets] = useState(1); // Number of tickets to buy
  const [transactionStatus, setTransactionStatus] = useState(null); // Transaction feedback
  const [isProcessing, setIsProcessing] = useState(false); // Button loading state
  const widgetRef = useRef(null); // Ref for dynamic widget sizing

  // Adjust widget size based on content
  useEffect(() => {
    if (widgetRef.current) {
      const widget = widgetRef.current;
      const content = widget.querySelector(".widget-content");
      const { width, height } = content.getBoundingClientRect();

      // Adjust size dynamically if content overflows
      if (width > widget.offsetWidth || height > widget.offsetHeight) {
        widget.style.width = `${width + 20}px`;
        widget.style.height = `${height + 20}px`;
      }
    }
  }, [selectedPool]);

  if (!selectedPool) {
    return (
      <div ref={widgetRef} style={widgetStyle}>
        <div className="widget-header" style={headerStyle}>
          <strong>Buy Tickets</strong>
        </div>
        <div className="widget-content" style={contentStyle}>
          <p>Select a pool from the left to begin.</p>
        </div>
      </div>
    );
  }

  const incrementTickets = () => {
    if (tickets < selectedPool.totalTickets) {
      setTickets((prev) => prev + 1);
    }
  };

  const decrementTickets = () => {
    if (tickets > 1) {
      setTickets((prev) => prev - 1);
    }
  };

  const handlePurchase = async () => {
    if (!connected) {
      alert("Please connect your wallet!");
      return;
    }
  
    if (!TREASURY_WALLET) {
      alert("Treasury wallet is not configured. Please check your environment variables.");
      return;
    }
  
    setIsProcessing(true); // Show loading state
  
    try {
      const treasuryPubKey = new PublicKey(TREASURY_WALLET); // Load treasury wallet
      const lamports = tickets * selectedPool.priceInSol * 1e9; // Calculate cost in lamports
  
      // Create a new transaction
      const transaction = new Transaction().add(
        SystemProgram.transfer({
          fromPubkey: publicKey,
          toPubkey: treasuryPubKey,
          lamports,
        })
      );
  
      // Add blockhash and fee payer information
      const { blockhash } = await connection.getLatestBlockhash();
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = publicKey;
  
      // Use `sendTransaction` (which internally uses `signAndSendTransaction` when available)
      const signature = await sendTransaction(transaction, connection);
  
      // Update the transaction status
      setTransactionStatus("Processing...");
      await connection.confirmTransaction(signature, "confirmed");
      setTransactionStatus("Transaction Confirmed!");
  
      // Notify user of success
      alert(`✅ Purchased ${tickets} tickets for ${selectedPool.name}!\nTransaction ID: ${signature}`);
  
      // Send transaction details to the backend for validation
      await handleTransactionConfirmation(signature, tickets, selectedPool.priceInSol * tickets);
    } catch (error) {
      console.error("Transaction failed:", error);
      setTransactionStatus("Transaction Failed");
      alert("❌ Transaction failed! Please try again.");
    } finally {
      setIsProcessing(false); // Reset loading state
    }
  };
  
  // Helper function to send transaction details to the backend
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:5000";

  const handleTransactionConfirmation = async (txId) => {
    try {
      const response = await fetch(`${BACKEND_URL}/api/confirm-transaction`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          pool_id: selectedPool.id,
          num_tickets: tickets,
          transaction_signature: txId,
          wallet_address: publicKey.toBase58(),
        }),
      });
  
      const result = await response.json();
  
      if (response.ok) {
        console.log("Transaction confirmed on the backend:", result);
        alert("Transaction successfully logged in the database!");
      } else {
        console.error("Backend transaction confirmation failed:", result);
        alert("Transaction confirmation failed on the backend!");
      }
    } catch (error) {
      console.error("Error sending transaction confirmation to backend:", error);
    }
  };
  
  return (
    <div ref={widgetRef} style={widgetStyle}>
      <div className="widget-header" style={headerStyle}>
        <strong>Buy Tickets for {selectedPool.name}</strong>
      </div>
      <div className="widget-content" style={contentStyle}>
        <img
          src={selectedPool.image}
          alt={selectedPool.name}
          style={{
            width: "100%",
            height: "150px",
            objectFit: "cover",
            borderRadius: "8px",
            marginBottom: "10px",
          }}
        />
        <p>Prize: {selectedPool.prize}</p>
        <p>Price per Ticket: {selectedPool.priceInSol} SOL</p>
        <p>Total Tickets Available: {selectedPool.totalTickets}</p>
        <p style={{ color: transactionStatus === "Transaction Confirmed!" ? "green" : "red" }}>
          {transactionStatus}
        </p>

        <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
          <button onClick={decrementTickets} style={buttonStyle} disabled={isProcessing}>
            -
          </button>
          <input type="number" value={tickets} readOnly style={inputStyle} />
          <button onClick={incrementTickets} style={buttonStyle} disabled={isProcessing}>
            +
          </button>
        </div>

        <button onClick={handlePurchase} style={purchaseButtonStyle} disabled={isProcessing}>
          {isProcessing ? "Processing..." : `Purchase ${tickets} Ticket(s)`}
        </button>
      </div>
    </div>
  );
};

// Styles
const widgetStyle = {
  border: "1px solid #ccc",
  borderRadius: "8px",
  background: "#f9f9f9",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "10px",
  minWidth: "400px",
  minHeight: "450px",
  boxSizing: "border-box",
};

const headerStyle = {
  background: "#f0f0f0",
  padding: "10px",
  textAlign: "center",
  width: "100%",
  fontWeight: "bold",
};

const contentStyle = {
  flex: 1,
  padding: "10px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

const buttonStyle = {
  width: "40px",
  height: "40px",
  fontSize: "18px",
  borderRadius: "50%",
  border: "1px solid #007BFF",
  background: "#fff",
  cursor: "pointer",
  margin: "0 5px",
};

const inputStyle = {
  width: "60px",
  textAlign: "center",
  border: "1px solid #ccc",
  borderRadius: "4px",
  margin: "0 5px",
};

const purchaseButtonStyle = {
  marginTop: "20px",
  padding: "10px 20px",
  background: "#007BFF",
  color: "#fff",
  border: "none",
  borderRadius: "8px",
  cursor: "pointer",
};

export default Widget2;
