import * as THREE from 'three';
import poolsConfig from '../../config/poolsConfig'; // Import pool config

const createCube8 = () => {
  const pool = poolsConfig[0]; // Dynamically get pool data
  const geometry = new THREE.BoxGeometry(3, 2, 0.2); // Larger cube for better visibility
  const material = new THREE.MeshStandardMaterial({ color: 0x00ffff }); // Cyan
  const cube = new THREE.Mesh(geometry, material);

  // Create Pulsating Marker
  const createMarker = () => {
    const markerGeometry = new THREE.SphereGeometry(0.3, 16, 16); // Larger sphere
    const markerMaterial = new THREE.MeshStandardMaterial({
      color: 0xff0000, // Red
      emissive: 0xff0000,
      emissiveIntensity: 1,
    });
    const marker = new THREE.Mesh(markerGeometry, markerMaterial);

    // Animate the marker to pulse
    marker.userData.pulseSpeed = 0.05;
    marker.userData.animatePulse = (elapsedTime) => {
      const scale = 1.5 + Math.sin(elapsedTime * 3) * 0.5; // Bigger pulsating effect
      marker.scale.set(scale, scale, scale);
    };

    marker.position.set(0, 3, 0); // Position marker above the cube
    return marker;
  };

  // Attach the marker to the cube
  const marker = createMarker();
  cube.add(marker);

  // Create Pool Data Hologram
  const createHologram = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = 1024; // Increased canvas resolution
    canvas.height = 512;

    // Style the text for the hologram
    ctx.fillStyle = 'rgba(0, 0, 0, 0.8)';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = '#00ffff';
    ctx.font = '40px Arial'; // Larger font for better readability
    ctx.textAlign = 'center';
    ctx.fillText(pool.name, canvas.width / 2, 100);
    ctx.fillText(`Price: ${pool.priceInSol} SOL`, canvas.width / 2, 200);
    ctx.fillText(`Prize: ${pool.prize}`, canvas.width / 2, 300);

    const texture = new THREE.CanvasTexture(canvas);
    const hologramMaterial = new THREE.SpriteMaterial({ map: texture });
    const hologram = new THREE.Sprite(hologramMaterial);

    hologram.position.set(0, 15, 0); // Position hologram well above the cube and marker
    hologram.scale.set(90, 75, 50); // Significantly larger hologram size
    return hologram;
  };

  const hologram = createHologram();
  cube.add(hologram);

  // Attach animation behavior for the marker
  cube.userData.animate = (elapsedTime) => {
    marker.userData.animatePulse(elapsedTime);
  };

  return cube;
};

export default createCube8;
