import React from 'react';
import { Html } from '@react-three/drei';
import * as THREE from 'three';

const createCube5 = () => {
  // Pool configuration data
  const poolsConfig = {
    name: "WIN 5 SOL",
    priceInSol: 0.03,
    totalTickets: 50,
    duration: 3 * 24 * 60 * 60 * 1000, // 3 days in milliseconds
    entryCap: 10,
    image: "https://via.placeholder.com/150",
    prize: "1 SOL",
  };

  // Cube geometry and material
  const geometry = new THREE.BoxGeometry(1, 1, 1);
  const material = new THREE.MeshStandardMaterial({ color: 0xff00ff }); // Purple
  const cube = new THREE.Mesh(geometry, material);

  // Adding interactivity
  cube.userData.onClick = () => {
    const htmlContent = document.createElement('div');
    htmlContent.style.position = 'absolute';
    htmlContent.style.background = 'rgba(0, 0, 0, 0.8)';
    htmlContent.style.color = 'white';
    htmlContent.style.padding = '10px';
    htmlContent.style.borderRadius = '8px';
    htmlContent.style.textAlign = 'center';
    htmlContent.style.width = '200px';
    htmlContent.style.zIndex = '1000';

    htmlContent.innerHTML = `
      <img src="${poolsConfig.image}" alt="${poolsConfig.name}" style="width: 100%; border-radius: 8px;">
      <h3>${poolsConfig.name}</h3>
      <p><strong>Price:</strong> ${poolsConfig.priceInSol} SOL</p>
      <p><strong>Total Tickets:</strong> ${poolsConfig.totalTickets}</p>
      <p><strong>Duration:</strong> ${poolsConfig.duration / (24 * 60 * 60 * 1000)} days</p>
      <p><strong>Entry Cap:</strong> ${poolsConfig.entryCap}</p>
      <p><strong>Prize:</strong> ${poolsConfig.prize}</p>
      <button style="margin-top: 10px; padding: 5px; background: #ff00ff; color: white; border: none; border-radius: 4px; cursor: pointer;">Close</button>
    `;

    // Append to the body
    document.body.appendChild(htmlContent);

    // Close button functionality
    htmlContent.querySelector('button').addEventListener('click', () => {
      document.body.removeChild(htmlContent);
    });
  };

  return cube;
};

export default createCube5;
