import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './WelcomePage.module.css';

function WelcomePage() {
    const [typedText, setTypedText] = useState('');
    const fullText = "Welcome to Dust 2 Diamonds!"; // Ensure the string is accurate

    useEffect(() => {
        let index = 0;

        // Typing effect
        const typingInterval = setInterval(() => {
            if (index <= fullText.length) {
                // Append one character at a time
                setTypedText(fullText.slice(0, index + 1)); // Safely slice the string
                index++;
            } else {
                clearInterval(typingInterval);
            }
        }, 100);

        // Cleanup the interval on unmount
        return () => clearInterval(typingInterval);
    }, []);

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>
                {typedText}
                <span className={styles.cursor}>|</span>
            </h1>
            <p className={styles.subheading}>
                Come with us on this journey where success is guaranteed to 1000x!
            </p>
            <Link to="/home">
                <button className={styles.enterButton}>Enter</button>
            </Link>
        </div>
    );
}

export default WelcomePage;
