// src/config/poolsConfig.js
const poolsConfig = [
    {
      name: "WIN 20 SOL",
      priceInSol: 0.03,
      totalTickets: 50,
      duration: 3 * 24 * 60 * 60 * 1000, // 3 days in milliseconds
      entryCap: 10,
      image: "https://via.placeholder.com/150",
      prize: "1 SOL",
    },
    {
      name: "WIN 3 SOL",
      priceInSol: 0.09,
      totalTickets: 100,
      duration: 5 * 24 * 60 * 60 * 1000, // 5 days
      entryCap: 5,
      image: "https://via.placeholder.com/150",
      prize: "3 SOL",
    },
    {
      name: "Win a Trip to Bahamas",
      priceInSol: 0.2599,
      totalTickets: 25,
      duration: 2 * 24 * 60 * 60 * 1000, // 2 days
      entryCap: 2,
      image: "https://via.placeholder.com/150",
      prize: "Trip to Bahamas",
    },
  ];
  
  export default poolsConfig;
  