import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import WelcomePage from './WelcomePage';
import HomePage from './HomePage';
import PlayPage from './components/PlayPage';
import DashboardPage from './DashboardPage';
import Orb from './solar/orb'; // Import the Orb component

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/welcome" element={<WelcomePage />} />
                <Route path="/home" element={<HomePage />} />
                <Route path="/play" element={<PlayPage />} />
                <Route path="/dashboard" element={<DashboardPage />} />
                <Route path="/orb" element={<Orb />} /> {/* Add Orb route */}
                <Route path="/" element={<Navigate to="/welcome" />} />
            </Routes>
        </Router>
    );
}

export default App;
