import * as THREE from 'three';

const createCube1 = () => {
  const textureLoader = new THREE.TextureLoader();
  const texture = textureLoader.load('/phantom3.png'); // Path to your texture
  const material = new THREE.MeshBasicMaterial({ map: texture });
  const geometry = new THREE.SphereGeometry(1, 32, 32);
  const cube = new THREE.Mesh(geometry, material);

  // Position the cube
  cube.position.set(-3, 0, 0);

  // Add a `clickable` property for interaction
  cube.userData = { clickable: true };

  return cube;
};

export default createCube1;
