import React from 'react';
import { Link } from 'react-router-dom';

function DashboardPage() {
    return (
        <div style={{ textAlign: 'center', padding: '20px' }}>
            <h1>Dashboard</h1>
            <p>Check out your stats and progress!</p>
            <Link to="/home">
                <button style={{ padding: '10px 20px' }}>Back to Home</button>
            </Link>
        </div>
    );
}

export default DashboardPage;
