import * as THREE from 'three';

const createCube11 = () => {
  const geometry = new THREE.BoxGeometry(1, 1, 1);
  const material = new THREE.MeshStandardMaterial({ color: 0x00ffff }); // Cyan
  const cube = new THREE.Mesh(geometry, material);

  cube.position.set(0, 0, 0); // Default position
  return cube;
};

export default createCube11;
