import React, { useState, useEffect, useRef } from "react";
import { usePool } from "../../context/PoolContext";
import poolsConfig from "../../config/poolsConfig";

const Widget1 = () => {
  const { setSelectedPool } = usePool();
  const [currentRotation, setCurrentRotation] = useState(0);
  const widgetRef = useRef(null); // Ref for the widget container

  // Adjust the widget size based on content
  useEffect(() => {
    if (widgetRef.current) {
      const widget = widgetRef.current;
      const content = widget.querySelector(".carousel-container");
      const { width, height } = content.getBoundingClientRect();

      // Dynamically adjust widget size if content overflows
      if (width > widget.offsetWidth || height > widget.offsetHeight) {
        widget.style.width = `${width + 20}px`; // Add padding
        widget.style.height = `${height + 20}px`;
      }
    }
  }, []);

  const rotateCarousel = (direction) => {
    const angle = 360 / poolsConfig.length; // Divide the circle into equal parts
    setCurrentRotation((prev) => prev + (direction === "left" ? angle : -angle));
  };

  return (
    <div ref={widgetRef} style={widgetStyle}>
      <div className="widget-header" style={headerStyle}>
        <strong>Available Pools</strong>
      </div>
      <div className="carousel-container" style={carouselContainerStyle}>
        <div
          style={{
            ...carouselStyle,
            transform: `rotateY(${currentRotation}deg)`, // Rotate carousel
          }}
        >
          {poolsConfig.map((pool, index) => (
            <div
              key={index}
              onClick={() => setSelectedPool(pool)} // Set pool on click
              style={{
                ...cardStyle,
                transform: `rotateY(${index * (360 / poolsConfig.length)}deg) translateZ(300px)`,
              }}
            >
              <img
                src={pool.image}
                alt={pool.name}
                style={cardImageStyle}
              />
              <h4 style={{ margin: "10px 0", fontSize: "16px" }}>{pool.name}</h4>
              <p style={{ margin: "5px 0", fontSize: "14px" }}>Prize: {pool.prize}</p>
              <p style={{ margin: "5px 0", fontSize: "14px" }}>Price: {pool.priceInSol} SOL</p>
            </div>
          ))}
        </div>
      </div>
      <div style={navigationStyle}>
        <button onClick={() => rotateCarousel("left")} style={navButtonStyle}>
          ◀
        </button>
        <button onClick={() => rotateCarousel("right")} style={navButtonStyle}>
          ▶
        </button>
      </div>
    </div>
  );
};

// Styles
const widgetStyle = {
  border: "1px solid #ccc",
  borderRadius: "8px",
  background: "#f9f9f9",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "10px",
  minWidth: "400px", // Minimum width to accommodate the carousel
  minHeight: "450px", // Minimum height to accommodate the carousel
  boxSizing: "border-box", // Include padding and border in dimensions
};

const headerStyle = {
  background: "#f0f0f0",
  padding: "10px",
  textAlign: "center",
  width: "100%",
  fontWeight: "bold",
};

const carouselContainerStyle = {
  perspective: "1000px", // Creates a 3D effect
  width: "100%",
  height: "300px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  overflow: "hidden",
};

const carouselStyle = {
  width: "100%",
  height: "300px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  transformStyle: "preserve-3d",
  transition: "transform 1s ease",
};

const cardStyle = {
  width: "150px",
  height: "200px",
  position: "absolute",
  transformStyle: "preserve-3d",
  backfaceVisibility: "hidden",
  textAlign: "center",
  border: "1px solid #ccc",
  borderRadius: "8px",
  background: "#fff",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
};

const cardImageStyle = {
  width: "100%",
  height: "100px",
  objectFit: "cover",
  borderTopLeftRadius: "8px",
  borderTopRightRadius: "8px",
};

const navigationStyle = {
  marginTop: "20px",
  display: "flex",
  justifyContent: "space-between",
  width: "100px",
};

const navButtonStyle = {
  background: "#007BFF",
  color: "#fff",
  border: "none",
  borderRadius: "50%",
  width: "40px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  fontSize: "18px",
  outline: "none",
};

export default Widget1;
